import {
  Alert,
  Card,
  Col,
  message,
  Row,
  Spin,
  Typography,
} from 'antd'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import qs from 'qs'
import React, { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { FormattedMessage } from 'react-intl'

import RaffleUtils from '../../utils/raffle'
import RaffleActions from '../../actions/raffles'
import SiteMetaData from '../../components/Confirmation/SiteMetaData'

const Widget = ({ location }) => {
  // get raffle id from the location object or the query string
  const params = qs.parse(location.search, { ignoreQueryPrefix: true })
  const id = location.state ? location.state.id : params.id
  const industry = location.state ? location.state.industry : params.industry
  const [raffle, setRaffle] = useState({})
  const [loading, setLoading] = useState(true)
  const { siteUrl } = SiteMetaData()

  const getRaffle = useCallback(
    async () => {
      try {
        setLoading(true)
        const newRaffle = await RaffleActions.get(industry, id)
        setLoading(false)

        setRaffle(newRaffle)
      } catch (ex) {
        message.warn(ex.message)
      }
    },
    [id, industry],
  )

  useEffect(() => {
    if (id && industry) {
      // get raffle details
      getRaffle()
    } else {
      // use empty object since the raffle get() will return an empty object if not found
      setRaffle({})
      setLoading(false)
    }
  }, [getRaffle, id, industry])

  return (
    <>
      {/* override meta settings in the basic layout component */}
      <Helmet
        meta={[
          { name: 'description', content: RaffleUtils.getPlainDescription(raffle) },
          { name: 'keywords', content: raffle.title },

          { property: 'og:description', content: RaffleUtils.getPlainDescription(raffle) },
          { property: 'og:image', content: RaffleUtils.getImageSrc(raffle) },
          { property: 'og:title', content: raffle.title },
          { property: 'og:url', content: siteUrl + RaffleUtils.getDetailsUrl(raffle) },
          { property: 'twitter:image:src', content: RaffleUtils.getImageSrc(raffle) },
          { property: 'twitter:title', content: raffle.title },
          { property: 'twitter:description', content: RaffleUtils.getPlainDescription(raffle) },
        ]}
        title={raffle.title}
      />
      {loading && (
        <Row>
          <Col md={24}>
            <Card className="center">
              <Spin tip="Loading..." />
            </Card>
          </Col>
        </Row>
      )}
      <Row type="flex" justify="center">
        {raffle.status === 'draft' && (
        <div className="mb2">
          <Alert
            description={<FormattedMessage id="comp.raffledetails.draft.desc" />}
            message={<FormattedMessage id="comp.raffledetails.draft.message" />}
            showIcon
            type="warning"
          />
        </div>
        )}
        {!RaffleUtils.isPublished(raffle) && (
        <div className="mb2">
          {RaffleUtils.isUnsuccessful(raffle) && (
          <Alert
            description={(
              <Link state={{ industry: raffle.industry }} to={`/${raffle.industry}/`}>
                <FormattedMessage id="comp.raffledetails.over.desc" />
              </Link>
            )}
            message={(
              <FormattedMessage
                id="comp.raffledetails.backer.over.message"
              />
            )}
            showIcon
            type="info"
          />
          )}
          {RaffleUtils.isSuccessful(raffle) && (
            <Alert
              description={(
                <Link state={{ industry: raffle.industry }} to={`/${raffle.industry}/`}>
                  <FormattedMessage id="comp.raffledetails.over.desc" />
                </Link>
              )}
              message={(
                <FormattedMessage
                  id={`comp.raffledetails.${RaffleUtils.isPaid(raffle) ? 'contest' : 'giveaway'}.over.message`}
                />
              )}
              showIcon
              type="info"
            />
          )}
        </div>
        )}
      </Row>
      {RaffleUtils.isPublished(raffle) && (
      <Card
        style={{ width: 300 }}
        cover={(
          <img
            alt="example"
            src={RaffleUtils.getImageSrc(raffle)}
          />
        )}
      >
        <Card.Meta
          title={(
            <Link
              state={{ id: raffle.id, industry: raffle.industry }}
              to={RaffleUtils.getDetailsUrl(raffle)}
            >
              {raffle.title}
            </Link>
            )}
          description={(
            <Typography.Paragraph ellipsis={{ rows: 4 }}>
              {RaffleUtils.getPlainDescription(raffle)}
            </Typography.Paragraph>
            )}
        />
      </Card>
      )}
    </>
  )
}

Widget.propTypes = {
  location: PropTypes.object.isRequired,
}

export default Widget
