import { capitalize, isEmpty, max } from 'lodash'
import moment from 'moment'

import amplify from '../assets/conf/amplify.json'
import PlaceHolder from '../assets/img/placeholder.png'
import GameLogo from '../assets/img/game_logo_small.svg'

export default class RaffleUtils {
  static getDetailsUrl = (raffle) => (
    `/raffle/?industry=${raffle.industry}&id=${raffle.id}`
  )

  static getImageSrc = (raffle) => {
    // get raffle image source url
    let imgSrc = PlaceHolder
    if (!isEmpty(raffle.images)) {
      imgSrc = `${amplify.storageEndpoint}/raffles/${raffle.id}/${raffle.images[0]}`
    }

    return imgSrc
  }

  static getProductImageSrc = (raffle, imageId) => {
    // get product image source url
    let imgSrc = PlaceHolder
    if (!isEmpty(imageId)) {
      imgSrc = `${amplify.storageEndpoint}/raffles/${raffle.id}/${imageId}`
    }

    return imgSrc
  }

  static getGameLogo = () => GameLogo

  static getPlainDescription = (raffle) => {
    // remove html tags from description to show on index page
    if (raffle.description) {
      return raffle.description.replace(/<[^>]+>/g, '')
    }
    return ''
  }

  static getRemainingDays = (timestamp) => (
    // timestamp is a unix timestamp
    max([moment.unix(timestamp).diff(moment(), 'days') + 1, 0])
  )

  static getSponsorFullName = (firstname, lastname) => (
    `${capitalize(firstname)} ${capitalize(lastname)}`
  )

  static isBackerCampaign = (raffle) => (
    raffle.status === 'backer-campaign'
  )

  static isLive = (raffle) => (
    raffle.status === 'live'
  )

  static isPublished = (raffle) => (
    ['backer-campaign', 'live'].includes(raffle.status)
  )

  static needsBacker = (raffle) => (
    raffle.minBackers > 0
  )

  static isPaid = (raffle) => (
    raffle.raffleType === 'paid'
  )

  static isLiveGiveaway = (raffle) => (
    this.isLive(raffle) && !this.isPaid(raffle)
  )

  static isLiveContest = (raffle) => (
    this.isLive(raffle) && this.isPaid(raffle)
  )

  static isBackerCampaignGiveaway = (raffle) => (
    this.isBackerCampaign(raffle) && !this.isPaid(raffle)
  )

  static isBackerCampaignContest = (raffle) => (
    this.isBackerCampaign(raffle) && this.isPaid(raffle)
  )

  static isUnsuccessful = (raffle) => (
    raffle.status === 'unsuccessful'
  )

  static isSuccessful = (raffle) => (
    raffle.status === 'needs-winner' || raffle.status === 'winner-selected'
  )
}
